export default {
  "shared": {
    "lunds-university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunds Universitet"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurser"])},
    "courseCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurskod"])},
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin"])},
    "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startad"])},
    "ended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avslutad"])},
    "go-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå tillbaka"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nej"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera"])},
    "editCoordinator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera samordnare"])},
    "editSupportDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera beviljat stöd"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radera"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj"])},
    "confirm-delete-thing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Är du säker att du vill radera ", _interpolate(_named("item")), "?"])},
    "confirm-removal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekräfta borttagning"])},
    "confirm-remove-thing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Är du säker att du vill ta bort ", _interpolate(_named("item")), "?"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekräfta"])},
    "remove_thing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ta bort ", _interpolate(_named("thing"))])},
    "enable_thing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aktivera ", _interpolate(_named("thing"))])},
    "disable_thing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Inaktivera ", _interpolate(_named("thing"))])},
    "no-things": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Inga ", _interpolate(_named("things"))])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök"])},
    "interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intresse"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "back-to-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillbaka till översikt"])},
    "back-previous-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillbaka till föregående sida"])},
    "go-home-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå till startsida"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptera"])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avböj"])},
    "accept-policy-full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jag accepterar villkoren"])},
    "scroll-to-top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hela vägen upp"])},
    "saveSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigering sparad!"])},
    "saveUnsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyvärr gick något fel och ändringarna sparades inte"])},
    "search-and-filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök och filtrera"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["År"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Månad"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dag"])},
    "update-input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nytt personnummer"])},
    "four-digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fyra siffror"])},
    "download-excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportera till excel"])},
    "invalid-date-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det valda datumintervallet är ogiltigt. Vänligen kontrollera att startdatumet är före eller samma som slutdatumet."])},
    "routes": {
      "note-taker-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteckningsstöd översikt"])},
      "note-taker-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteckningsstöd detaljerad"])},
      "student-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student detaljerad"])},
      "note-taking-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intresseanmälan anteckningsstöd / Dela anteckningar"])},
      "mentorship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentorskap"])},
      "notetaking-support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteckningsstöd"])},
      "tuition-fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studieavgifter"])},
      "update-personal-identity-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnummersbyte"])},
      "suspension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppskov"])},
      "postponement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansökan om anstånd"])},
      "add-student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till Student"])},
      "student-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startsida"])},
      "employee-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startsida anställd"])},
      "course-occurrence-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurstillfälle översikt"])},
      "course-occurrence-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurstillfälle detaljerad"])},
      "student-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student översikt"])},
      "offer-response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteckninsstöd svarssida"])},
      "share-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dela anteckningar"])},
      "personal-identity-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnummer"])}
    },
    "form": {
      "date-picker": {
        "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nu"])},
        "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vecka"])},
        "increment-seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öka sekund"])},
        "increment-minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öka minut"])},
        "increment-housr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öka timme"])},
        "decrement-seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minska sekund"])},
        "decrement-minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minska minut"])},
        "decrement-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minska timme"])},
        "next-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nästa månad"])},
        "prev-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Föregående månad"])}
      }
    },
    "app-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studentportalen"])},
    "coordinator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samordnare"])},
    "proposedNoteTakersCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intresseanmälda"])},
    "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erbjudande"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nollställ"])},
    "popup-error": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ojdå ", _interpolate(_named("name")), "!"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Någonting gick tyvärr fel, var vänlig och testa senare."])}
    }
  },
  "header": {
    "show-hide-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa/Dölj sök"])},
    "show-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa meny"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meny"])},
    "listen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lyssna"])},
    "select-locale": {
      "change-language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change language to english"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])}
    }
  },
  "main": {
    "page-manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidansvarig"])}
  },
  "modal": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stäng modal"])}
  },
  "footer": {
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakta oss"])},
    "shortcuts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genvägar"])}
  },
  "auth": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga ut"])}
  },
  "unauthorized": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obehörig"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det verkar som att du inte har behörighet att komma åt den här sidan. Se till att du är behörig att se detta innehåll. Om du tycker att du borde kunna se den här sidan, vänligen kontakta oss."])}
  },
  "not-found": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hittades inte"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidan du letar efter kunde inte hittas. Om du tycker att du borde kunna se den här sidan, vänligen kontakta oss."])}
  },
  "person": {
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
    "personalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnummer"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förnamn"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efternamn"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namn"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adress"])},
    "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postnummer"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postort"])},
    "telephoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
    "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
    "coordinator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samordnare"])},
    "grantedSupportDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beviljat stöd tom"])},
    "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Displaynamn"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användarnamn"])},
    "affiliation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eduPersonAffiliation"])},
    "primaryAffiliation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eduPersonPrimaryAffiliation"])},
    "lu-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LU-Konto"])},
    "email-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadress"])}
  },
  "note-taker-details": {
    "back-to-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillbaka till översikt"])},
    "checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checklista"])},
    "administration-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteckningar"])},
    "enter-new-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange en ny anteckning här"])},
    "note-taker-abbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AT"])}
  },
  "student-details": {
    "saveSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigering sparad!"])},
    "saveUnsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyvärr gick något fel och studenten sparades inte"])},
    "no-coordinator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga samordnaren"])},
    "edit-btn-tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera samordnare och/eller datum för beviljat stöd"])},
    "activeNoteTaker": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Det är inte längre möjligt att uppdatera denna student<br />", _interpolate(_named("full_name")), " har nu minst ett aktivt uppdrag eller en aktiv intresseanmälan som anteckningsstöd"])}
  },
  "note-taker-list": {
    "show-students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa student"])},
    "export-fee-basis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvode Underlag"])},
    "export-fee-accounting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arvode Konteringsunderlag"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportera"])},
    "choose-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj Datum"])},
    "choose-date-explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om du anger ett datum exporteras endast data från och med detta datum och allt nytt (ej tidigare exporterade) , annars bara allt nytt"])}
  },
  "student-list": {
    "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utgånget stöd"])}
  },
  "note-taking-request": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intresseanmälan anteckningsstöd / Dela anteckningar"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>På denna sida kan du anmäla intresse som anteckningsstöd för de kurser du är registrerad på, samt dela anteckningar för de kurser du fått uppdrag som anteckningsstöd på.</p><p>För att anmäla intresse som anteckningsstöd, bocka i de kurser du är intresserad av och klicka sedan på knappen <span style='font-weight:600;'>Spara</span>. Knappen heter <span style='font-weight:600;'>Uppdatera</span> om du anmält intresse tidigare.</p><p>För att dela anteckningar klicka på knappen <span style='font-weight:600;'>Dela anteckningar</span> som finns till höger om kurserna du fått uppdrag som anteckningsstöd på.</p>"])},
    "info-about-you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information om dig"])},
    "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansökan"])},
    "choose-campus-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vilket campus studerar du vid?"])},
    "choose-campus-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj ett campus"])},
    "choose-faculty-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vilken fakultet tillhör du?"])},
    "choose-faculty-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj en fakultet"])},
    "choose-courses-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj de kurser du önskar att ge anteckningsstöd på"])},
    "edit-information-ladok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stämmer inte din information? Redigera den i"])},
    "add-student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till student"])},
    "why-add-student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till en student om det är någon särskild du vill ge stöd till."])},
    "send-application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka ansökan"])},
    "alert-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man måste välja minst en kurs"])},
    "campuses": {
      "helsingborg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus Helsingborg"])},
      "lund-malmo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campus Lund eller Malmö"])}
    },
    "popup-success": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tack ", _interpolate(_named("name")), "!"])},
      "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tack för din anmälan. En bekräftelse har skickats till din e-post (", _interpolate(_named("email")), ")."])},
      "updateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dina ändringar har sparats."])}
    },
    "cannot-deregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eftersom du har ett aktivt uppdrag som anteckningsstöd för denna kurs kan du inte avanmäla dig från den"])},
    "cannot-reregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eftersom ditt uppdrag som anteckningsstöd för denna kurs avslutades i förtid kan du inte anmäla nytt intresse för denna kurs"])},
    "completed-course": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Denna kurs avslutades '", _interpolate(_named("date")), "'"])},
    "spring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vår"])},
    "autumn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höst"])},
    "popup-not-possible": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Förlåt ", _interpolate(_named("name"))])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan inte vara anteckningsstöd "])},
      "student-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samtidigt som du är registrerad att ta emot anteckningar."])},
      "interim-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["om du har ett tillfälligt personnummer."])}
    }
  },
  "offerresponse": {
    "popup-accepted": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tack ", _interpolate(_named("name")), "!"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tack för du antog uppdraget som anteckningsstöd."])}
    },
    "popup-declined": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdrag nekat"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdraget som anteckningsstöd har nekats"])}
    }
  },
  "pagination": {
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Föregående"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nästa"])},
    "goto-previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå till föregående sida"])},
    "goto-next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå till nästa sida"])},
    "goto": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gå till sida ", _interpolate(_named("page"))])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuvarande sida"])}
  },
  "form": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök"])},
    "search-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv för att söka"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrera"])},
    "filter-reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nollställ filter"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillämpa"])}
  },
  "note-taker-status": {
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "Incoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkommen"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
    "Inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
    "Completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avslutad"])}
  },
  "table": {
    "showingRowsOf": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Visar ", _interpolate(_named("start")), "-", _interpolate(_named("end")), " av ", _interpolate(_named("total"))])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen data"])}
  },
  "add-student": {
    "personalIdFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 siffror, eller med bindestreck"])},
    "studentExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angivet personnummer finns redan i systemet"])},
    "studentDoesNotExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angivet personnummer finns inte i Ladok"])},
    "saveSuccessful": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("full_name")), " är tillagd i listan med studenter"])},
    "saveUnsuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyvärr gick något fel och studenten sparades inte"])},
    "grantedSupportUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beviljat stöd till och med"])},
    "activeNoteTaker": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Personen finns redan registrerad!<br />", _interpolate(_named("full_name")), " har minst ett aktivt uppdrag eller en aktiv intresseanmälan som anteckningsstöd"])},
    "later-start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurserna nedan som är markerade har ett startdatum som är senare än det beviljade stöd till och med datum som du har angett."])}
  },
  "mandatoryField": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detta fält är obligatoriskt"])}
  },
  "course-occurrence": {
    "occurrence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurstillfälle"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursnamn"])},
    "courseStartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursstart"])},
    "courseEndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursslut"])},
    "studentCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
    "noteTakerCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteckningsstöd"])},
    "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studenter"])},
    "noteTakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteckningsstöd"])},
    "proposedNoteTakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteckningsstöd med intresse"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "abbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KT"])},
    "coordinatorName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samordnare KT"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stöd"])},
    "proposedNoteTakerDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erbjudande"])},
    "faculty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fakultet"])},
    "institution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution"])},
    "edit-btn-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera samordnare"])},
    "statusText": {
      "Green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteckningsstöd finns för detta kurstillfälle"])},
      "Yellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intresseanmälda finns för detta kurstillfälle"])},
      "Orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fler anteckningstöd behövs till detta kurstillfälle."])},
      "Red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteckningsstöd behövs för detta kurstillfälle"])},
      "Grey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varken behov eller intresseanmäld finns för detta kurstillfälle"])}
    },
    "remove-student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort student"])},
    "confirm-removal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekräfta uppdragets slut"])},
    "confirm-remove-note-taker": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Är du säker på att du vill avsluta uppdraget för ", _interpolate(_named("name")), "?"])},
    "assignment": {
      "assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdrag"])},
      "assignment-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdrag start"])},
      "assignment-end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdrag slut"])},
      "endAssignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avsluta uppdrag"])}
    },
    "offer": {
      "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erbjudande"])},
      "canceledOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbrutet erbjudande"])},
      "sendOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka erbjudande"])},
      "confirmSendOffer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Är du säker på att du vill skicka erbjudande till ", _interpolate(_named("name"))])},
      "cancelOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt erbjudande"])},
      "confirmCancelOffer": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Är du säker på avbryta erbjudande för ", _interpolate(_named("name"))])},
      "offerSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erbjudande skickat"])},
      "offerCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erbjudande avbrutet"])},
      "offer-response-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erbjudande av uppdrag som anteckningsstöd"])},
      "offer-response-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har fått ett erbjudande som anteckningsstöd på ett kurstillfälle. Gör ditt val nedan om du accepterar uppdraget eller ej."])},
      "offer-information-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information om uppdraget"])},
      "policy-required-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innan du accepterar erbjudandet som anteckningsstöd måste du godkänna "])},
      "terms-and-conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["villkoren"])}
    },
    "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otillgänglig"])},
    "unavailable-reason": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Det verkar som att kurstillfället '", _interpolate(_named("occurrence")), "' har tagits bort från Ladok."])},
    "uploads": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppladdningar"])},
      "note-taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteckningsstöd"])},
      "number-of-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal filer"])},
      "latest-upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senast uppladdning"])}
    }
  },
  "share-notes": {
    "page-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dela anteckningar för kurstillfälle"])},
    "note-taking-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteckningstillfälle datum"])},
    "note-taking-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteckningstillfälle klockan"])},
    "select-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj anteckningsfil"])},
    "file-size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filstorlek"])},
    "share-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dela anteckningar"])},
    "popup-success": {
      "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tack ", _interpolate(_named("name")), "!"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tack för att du laddade upp dina anteckningar."])}
    }
  },
  "file-upload": {
    "choose-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj Fil"])},
    "change-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byt fil"])},
    "no-file-chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen fil vald"])},
    "max-file-size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max filstorlek"])},
    "permitted-file-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillåtna filtyper"])},
    "file-size-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filstorleken måste vara mindre än"])},
    "file-type-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filen måste vara av följande typ(er)"])}
  },
  "tuition-fees": {
    "bank-transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banköverföring"])},
    "card-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kortbetalning"])},
    "mirror-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spegla användare"])},
    "search-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök användare"])},
    "search-term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sökterm"])},
    "search-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["personnummer (12 tkn), LU-konto eller namn"])},
    "search-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sökresultat"])},
    "minimum-char": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minst 2 tecken"])},
    "no-search-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din sökning gav inga resultat"])},
    "mirror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spegla"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillbaka"])},
    "mirroring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du speglar för närvarande"])},
    "stop-mirroring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluta spegla"])},
    "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalningsrespons"])},
    "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["okänt"])}
  },
  "student-portal-footer": {
    "column-1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktinformation"])}
    }
  },
  "tuition-fees-footer": {
    "column-1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about tuition fees"])}
    },
    "column-2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])}
    },
    "column-3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shortcuts"])}
    }
  },
  "personal-identity-number-footer": {
    "column-1": {
      "leading-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Studieadministrativa system<br/>Ladok (studentregistret)"])}
    }
  },
  "maintenance": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systemunderhåll"])},
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systemuppgradering pågår."])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En systemuppgradering av Studentportalen pågår för närvarande.<br/>Vi ber om ursäkt för eventuella olägenheter. Vänligen försök igen senare."])}
  },
  "date": {
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Från"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Till"])}
  }
}