/* istanbul ignore file */
import { createApp } from "vue";
import "@/assets/lu-theme/main.scss";
import "@/assets/css/app.css";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import i18n from "@/utilities/i18n";
import FontAwesomeIcon from "@/utilities/fontawesome";

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(i18n)
  .use(store)
  .use(router)
  .mount("#app");
