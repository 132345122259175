import { createI18n, LocaleMessages, VueMessageType } from "vue-i18n";
import enLocale from "../locales/en.json";
import svLocale from "../locales/sv.json";

const loadLocaleMessages = (): LocaleMessages<VueMessageType> => {
  const messages: LocaleMessages<VueMessageType> = {
    en: enLocale,
    sv: svLocale,
  };
  return messages;
};

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale:
    navigator.language.split("-")[0] || process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
});
