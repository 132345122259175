/* istanbul ignore file */
import { computed } from "vue";
import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import i18n from "@/utilities/i18n";
import AuthenticationService from "@/services/authentication-service";
import store from "@/store";

const {
  global: { t },
} = i18n;

const routes: Array<RouteRecordRaw> = [
  {
    path: "/tuitionfees",
    name: "TuitionFeeHome",
    component: () => import("../views/TuitionFees/TuitionFeeHome.vue"),
    meta: {
      title: computed(() => t("shared.routes.tuition-fees")).value,
      entitlements: ["Student", "or", "Tuition fees administratör"],
      titleKey: "shared.routes.tuition-fees",
      breadcrumbs: [
        {
          title: "shared.routes.student-home",
          path: "/",
        },
      ],
      footerContent: store.getters.getTuitionFeesFooterContent,
    },
    beforeEnter: (to, from) => {
      if (
        (from.name === "MirrorUser" ||
          from.name === "CardPayment" ||
          from.name === "BankTransfer" ||
          from.name === "PaymentResponse") &&
        AuthenticationService.hasEntitlements("Tuition fees administratör") &&
        to.meta
      ) {
        to.meta.breadcrumbs = [
          {
            title: "shared.routes.employee-home",
            path: "/administrator",
          },
          {
            title: "tuition-fees.mirror-user",
            path: "/administrator/tuitionfees/mirroruser",
          },
        ];
      }
    },
  },
  {
    path: "/",
    name: "StudentHome",
    component: () => import("@/views/StudentHome.vue"),
    meta: {
      title: computed(() => t("shared.routes.student-home")).value,
      allowAnonymous: true,
      titleKey: "shared.routes.student-home",
      breadcrumbs: [],
      footerContent: store.getters.getStudentPortalFooterContent,
    },
  },
  {
    path: "/note-taking-request",
    name: "NoteTakingRequest",
    component: () => import("../views/NoteTakingRequest.vue"),
    meta: {
      title: computed(() => t("shared.routes.note-taking-request")).value,
      entitlements: ["Student", "or", "SuperAdmin"],
      titleKey: "shared.routes.note-taking-request",
      breadcrumbs: [
        {
          title: "shared.routes.student-home",
          path: "/",
        },
      ],
      footerContent: store.getters.getStudentPortalFooterContent,
    },
  },
  {
    path: "/administrator",
    name: "EmployeeHome",
    component: () => import("../views/administrator/EmployeeHome.vue"),
    meta: {
      title: computed(() => t("shared.routes.employee-home")).value,
      entitlements: ["Employee", "or", "Tuition fees administratör"],
      titleKey: "shared.routes.employee-home",
      breadcrumbs: [],
    },
  },
  {
    path: "/administrator/notetakerlist",
    name: "NoteTakerList",
    component: () => import("../views/administrator/NoteTakerList.vue"),
    meta: {
      title: computed(() => t("shared.routes.note-taker-overview")).value,
      entitlements: [
        ["Anteckningsstödsadministratör", "or", "Anteckningsadministratör"],
        "or",
        "Samordnare",
      ],
      titleKey: "shared.routes.note-taker-overview",
      breadcrumbs: [
        {
          title: "shared.routes.employee-home",
          path: "/administrator",
        },
      ],
    },
  },
  {
    path: "/administrator/studentlist",
    name: "StudentList",
    component: () => import("../views/administrator/StudentList.vue"),
    meta: {
      title: computed(() => t("shared.routes.student-overview")).value,
      entitlements: [
        ["Anteckningsstödsadministratör", "or", "Anteckningsadministratör"],
        "or",
        "Samordnare",
      ],
      titleKey: "shared.routes.student-overview",
      breadcrumbs: [
        {
          title: "shared.routes.employee-home",
          path: "/administrator",
        },
      ],
    },
  },
  {
    path: "/administrator/notetakerdetails/:id",
    name: "NoteTakerDetails",
    component: () => import("../views/administrator/NoteTakerDetails.vue"),
    meta: {
      title: computed(() => t("shared.routes.note-taker-details")).value,
      entitlements: [
        ["Anteckningsstödsadministratör", "or", "Anteckningsadministratör"],
        "or",
        "Samordnare",
      ],
      titleKey: "shared.routes.note-taker-details",
      breadcrumbs: [
        {
          title: "shared.routes.employee-home",
          path: "/administrator",
        },
        {
          title: "shared.routes.note-taker-overview",
          path: "/administrator/notetakerlist",
        },
      ],
    },
  },
  {
    path: "/administrator/addstudent",
    name: "AddStudent",
    component: () => import("../views/administrator/AddStudent.vue"),
    meta: {
      title: computed(() => t("shared.routes.add-student")).value,
      entitlements: [
        ["Anteckningsstödsadministratör", "or", "Anteckningsadministratör"],
        "or",
        "Samordnare",
      ],
      titleKey: "shared.routes.add-student",
      breadcrumbs: [
        {
          title: "shared.routes.employee-home",
          path: "/administrator",
        },
        {
          title: "shared.routes.student-overview",
          path: "/administrator/studentlist",
        },
      ],
    },
  },
  {
    path: "/administrator/studentdetails/:id",
    name: "StudentDetails",
    component: () => import("../views/administrator/StudentDetails.vue"),
    meta: {
      title: computed(() => t("shared.routes.student-details")).value,
      entitlements: [
        ["Anteckningsstödsadministratör", "or", "Anteckningsadministratör"],
        "or",
        "Samordnare",
      ],
      titleKey: "shared.routes.student-details",
      breadcrumbs: [
        {
          title: "shared.routes.employee-home",
          path: "/administrator",
        },
        {
          title: "shared.routes.student-overview",
          path: "/administrator/studentlist",
        },
      ],
    },
  },
  {
    path: "/administrator/courseoccurrencelist",
    name: "CourseOccurrenceList",
    component: () => import("../views/administrator/CourseOccurrenceList.vue"),
    meta: {
      title: computed(() => t("shared.routes.course-occurrence-overview"))
        .value,
      entitlements: [
        ["Anteckningsstödsadministratör", "or", "Anteckningsadministratör"],
        "or",
        ["Samordnare", "or", "Läsbehörighet Anteckningsstöd"],
      ],
      titleKey: "shared.routes.course-occurrence-overview",
      breadcrumbs: [
        {
          title: "shared.routes.employee-home",
          path: "/administrator",
        },
      ],
    },
  },
  {
    path: "/administrator/courseoccurrencedetails/:id",
    name: "CourseOccurrenceDetails",
    component: () =>
      import("../views/administrator/CourseOccurrenceDetails.vue"),
    meta: {
      title: computed(() => t("shared.routes.course-occurrence-details")).value,
      entitlements: [
        ["Anteckningsstödsadministratör", "or", "Anteckningsadministratör"],
        "or",
        "Samordnare",
      ],
      titleKey: "shared.routes.course-occurrence-details",
      breadcrumbs: [
        {
          title: "shared.routes.employee-home",
          path: "/administrator",
        },
        {
          title: "shared.routes.course-occurrence-overview",
          path: "/administrator/courseoccurrencelist",
        },
      ],
    },
  },
  {
    path: "/offerresponse/:id",
    name: "OfferResponse",
    component: () => import("../views/OfferResponse.vue"),
    props: (route) => {
      const offerId = Number.parseInt(route.params.id.toString());
      return { offerId };
    },
    meta: {
      title: computed(() => t("shared.routes.offer-response")).value,
      entitlements: "Student",
      titleKey: "shared.routes.offer-response",
      breadcrumbs: [
        {
          title: "shared.routes.student-home",
          path: "/",
        },
      ],
      footerContent: store.getters.getStudentPortalFooterContent,
    },
  },
  {
    path: "/sharenotes/:id",
    name: "ShareNotes",
    component: () => import("../views/ShareNotes.vue"),
    meta: {
      title: computed(() => t("shared.routes.share-notes")).value,
      entitlements: "Student",
      titleKey: "shared.routes.share-notes",
      breadcrumbs: [{ title: "shared.routes.student-home", path: "/" }],
      footerContent: store.getters.getStudentPortalFooterContent,
    },
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: () => import("../views/Unauthorized.vue"),
    meta: {
      title: computed(() => t("unauthorized.title")).value,
      titleKey: "unauthorized.title",
      breadcrumbs: [],
      footerContent: store.getters.getStudentPortalFooterContent,
      allowAnonymous: true,
    },
  },
  {
    path: "/not-found",
    alias: ["/:pathMatch(.*)"],
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
    meta: {
      title: computed(() => t("not-found.title")).value,
      allowAnonymous: true,
      titleKey: "not-found.title",
      breadcrumbs: [],
      footerContent: store.getters.getStudentPortalFooterContent,
    },
  },
  {
    path: "/administrator/tuitionfees/mirroruser",
    name: "MirrorUser",
    component: () =>
      import("../views/TuitionFees/administrator/MirrorUser.vue"),
    meta: {
      title: computed(() => t("tuition-fees.mirror-user")).value,
      entitlements: "Tuition fees administratör",
      titleKey: "tuition-fees.mirror-user",
      breadcrumbs: [
        {
          title: "shared.routes.employee-home",
          path: "/administrator",
        },
      ],
    },
  },
  {
    path: "/tuitionfees/cardpayment/:invoiceNo",
    name: "CardPayment",
    component: () => import("../views/TuitionFees/CardPayment.vue"),
    meta: {
      title: computed(() => t("tuition-fees.card-payment")).value,
      entitlements: ["Student", "or", "Tuition fees administratör"],
      titleKey: "tuition-fees.card-payment",
      breadcrumbs: [
        {
          title: "shared.routes.student-home",
          path: "/",
        },
        {
          title: "shared.routes.tuition-fees",
          path: "/tuitionfees",
        },
      ],
      footerContent: store.getters.getTuitionFeesFooterContent,
    },
    beforeEnter: (to, from) => {
      if (
        from.name === "TuitionFeeHome" &&
        AuthenticationService.hasEntitlements("Tuition fees administratör") &&
        to.meta
      ) {
        to.meta.breadcrumbs = [
          {
            title: "shared.routes.employee-home",
            path: "/administrator",
          },
          {
            title: "tuition-fees.mirror-user",
            path: "/administrator/tuitionfees/mirroruser",
          },
          {
            title: "shared.routes.tuition-fees",
            path: from.path,
          },
        ];
      }
    },
  },
  {
    path: "/tuitionfees/banktransfer/:invoiceNo",
    name: "BankTransfer",
    component: () => import("../views/TuitionFees/BankTransfer.vue"),
    meta: {
      title: computed(() => t("tuition-fees.bank-transfer")).value,
      entitlements: ["Student", "or", "Tuition fees administratör"],
      titleKey: "tuition-fees.bank-transfer",
      breadcrumbs: [
        {
          title: "shared.routes.student-home",
          path: "/",
        },
        {
          title: "shared.routes.tuition-fees",
          path: "/tuitionfees",
        },
      ],
      footerContent: store.getters.getTuitionFeesFooterContent,
    },
    beforeEnter: (to, from) => {
      if (
        from.name === "TuitionFeeHome" &&
        AuthenticationService.hasEntitlements("Tuition fees administratör") &&
        to.meta
      ) {
        to.meta.breadcrumbs = [
          {
            title: "shared.routes.employee-home",
            path: "/administrator",
          },
          {
            title: "tuition-fees.mirror-user",
            path: "/administrator/tuitionfees/mirroruser",
          },
          {
            title: "shared.routes.tuition-fees",
            path: from.path,
          },
        ];
      }
    },
  },
  {
    path: "/tuitionfees/paymentresponse",
    name: "PaymentResponse",
    component: () => import("../views/TuitionFees/PaymentResponse.vue"),
    meta: {
      title: computed(() => t("tuition-fees.response")).value,
      allowAnonymous: true,
      titleKey: "tuition-fees.response",
      breadcrumbs: [
        {
          title: "shared.routes.student-home",
          path: "/",
        },
        {
          title: "shared.routes.tuition-fees",
          path: "/tuitionfees",
        },
      ],
      footerContent: store.getters.getTuitionFeesFooterContent,
    },
    beforeEnter: (to, from) => {
      if (
        from.name === "CardPayment" &&
        AuthenticationService.hasEntitlements("Tuition fees administratör") &&
        to.meta
      ) {
        to.meta.breadcrumbs = [
          {
            title: "shared.routes.employee-home",
            path: "/administrator",
          },
          {
            title: "tuition-fees.mirror-user",
            path: "/administrator/tuitionfees/mirroruser",
          },
          {
            title: "shared.routes.tuition-fees",
            path: "/tuitionfees",
          },
        ];
      }
    },
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: () => import("../views/Maintenance.vue"),
    meta: {
      title: computed(() => t("maintenance.title")).value,
      allowAnonymous: true,
      titleKey: "maintenance.title",
      breadcrumbs: [],
      footerContent: store.getters.getStudentPortalFooterContent,
    },
  },
  {
    path: "/personal-identity-number",
    name: "SocialSecurityNumber",
    component: () =>
      import("../views/SocialSecurityNumber/SocialSecurityNumber.vue"),
    meta: {
      title: computed(() => t("shared.routes.personal-identity-number")).value,
      entitlements: [["Student", "or", "Pnradministratör"], "or", "SuperAdmin"],
      titleKey: "shared.routes.personal-identity-number",
      breadcrumbs: [
        {
          title: "shared.routes.student-home",
          path: "/",
        },
      ],
      footerContent:
        store.getters.getStudentPortalSocialSecurityNumberFooterContent,
    },
  },
  {
    path: "/personal-identity-number/student",
    name: "SocialSecurityNumberStudent",
    component: () => import("../views/SocialSecurityNumber/Student.vue"),
    meta: {
      title: computed(() => t("shared.routes.personal-identity-number")).value,
      entitlements: [["Student", "or", "Pnradministratör"], "or", "SuperAdmin"],
      titleKey: "shared.routes.personal-identity-number",
      breadcrumbs: [
        {
          title: "shared.routes.student-home",
          path: "/",
        },
      ],
      footerContent:
        store.getters.getStudentPortalSocialSecurityNumberFooterContent,
    },
  },
  {
    path: "/personal-identity-number/admin",
    name: "SocialSecurityNumberAdmin",
    component: () => import("../views/SocialSecurityNumber/Admin.vue"),
    meta: {
      title: computed(() => t("shared.routes.personal-identity-number")).value,
      entitlements: [["Student", "or", "Pnradministratör"], "or", "SuperAdmin"],
      titleKey: "shared.routes.personal-identity-number",
      breadcrumbs: [
        {
          title: "shared.routes.student-home",
          path: "/",
        },
      ],
      footerContent:
        store.getters.getStudentPortalSocialSecurityNumberFooterContent,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Array använd case 1 / case 2 beroende på villkor. Hitta breadcrumbs genom meta
// Genomgå if satsen
router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    if (to.meta.allowAnonymous === true) {
      return next();
    }

    if (!AuthenticationService.isLoggedIn()) {
      try {
        await AuthenticationService.authenticate();
      } catch (error) {
        return next({ name: "Unauthorized", query: { redirect: from.path } });
      }
    }

    const entitlementGuard = to.meta.entitlements;
    if (entitlementGuard) {
      if (!AuthenticationService.hasEntitlements(entitlementGuard)) {
        return next({ name: "Unauthorized", query: { redirect: from.path } });
      }
    }

    return next();
  }
);

export default router;
