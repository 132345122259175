
import { computed } from "vue";
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import i18n from "@/utilities/i18n";
import type { IFooterColumn } from "@/interfaces/IFooterColumn";

const {
  global: { t },
} = i18n;

@Options({
  props: {
    /**
     * List of footer columns to display
     */
    footerContent: {
      type: Array as PropType<IFooterColumn[]>,
      required: false,
    },
  },
  computed: {
    locale(): string {
      return this.$root.$i18n.locale;
    },
    logoSrc(): string {
      return this.$i18n.locale === "sv"
        ? require("@/assets/Lunds_universitet_white.svg")
        : require("@/assets/Lunds_university_white.svg");
    },

    logoAlt(): string {
      return computed(() => t("shared.lunds-university")).value;
    },
  },
  methods: {
    /**
     * Translates the input string using the current locale
     */
    translate(localeKey: string): string {
      return computed(() => t(localeKey)).value;
    },
  },
})
export default class LuFooter extends Vue {}
