
import { Options, Vue } from "vue-class-component";
import { PropType } from "@vue/runtime-core";
import LuHeaderAuthButtons from "@/components/Lu/LuHeaderAuthButtons.vue";
import LuNavbar from "@/components/Lu/LuNavbar.vue";
import LuTopMenu from "@/components/Lu/LuTopMenu.vue";
import LuNavMobile from "@/components/Lu/LuNavMobile.vue";
import Badge from "@/components/Badge.vue";
import Search from "@/components/Form/Search.vue";
import LocalStorageService from "@/services/localstorage-service";
import { Constants } from "@/constants/constants";

interface Item {
  id: string;
  label?: string;
  text?: string;
  path?: string;
  children: Item[];
}

@Options({
  components: {
    LuHeaderAuthButtons,
    LuNavbar,
    LuTopMenu,
    Badge,
    LuNavMobile,
    Search,
  },
  props: {
    /**
     * Items in top menu
     */
    topmenu: { type: Array as PropType<Item[]>, default: null },
    /**
     * Items in mobile menu
     */
    mobilemenu: { type: Array as PropType<Item[]>, default: null },
    /**
     * Items en navbar menu
     */
    navbarmenu: { type: Array as PropType<Item[]>, default: null },
    /**
     * Has listen (show button)
     */
    hasListen: Boolean as PropType<boolean>,
    /**
     * Has search (show button)
     */
    hasSearch: Boolean as PropType<boolean>,
    /**
     * Empty the search value after searching
     */
    emptySearch: Boolean as PropType<boolean>,
    /**
     * Placeholder for search field
     */
    searchPlaceholder: { type: String as PropType<string>, default: "" },
    /**
     * Url of the logo (goto when click)
     */
    logoUrl: { type: String as PropType<string>, required: true },
    /**
     * Title of the logo
     */
    logoTitle: { type: String as PropType<string>, required: true },
    /**
     * Source of the logo
     */
    logoSrc: { type: String as PropType<string>, required: true },
    /**
     * Compact layout
     */
    compact: Boolean as PropType<boolean>,
    /**
     * Show the mobile menu
     */
    showMobileMenu: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    /**
     * Show the login buttons
     */
    showLogin: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    language: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      searchValue: "",
      searchCollapsed: true,
      /**
       * The current environment, Development etc.
       */
      environment: process.env.VUE_APP_ENV_NAME,
      currentLanguage:
        this.language ||
        LocalStorageService.getItem(Constants.Language) ||
        "sv",
    };
  },
  mounted() {
    if (this.hasListen) {
      // window._baMode = " "
      const baScript = document.createElement("script");
      baScript.setAttribute("src", "//www.browsealoud.com/plus/scripts/ba.js");
      baScript.async = true;
      document.head.appendChild(baScript);
    }
    // Set the language on the initial mount
    this.setLanguage(this.currentLanguage);
  },
  watch: {
    language(newLang: string) {
      this.setLanguage(newLang);
    },
  },
  methods: {
    /**
     * Change language
     */
    switchLocale(): void {
      const newLocale = this.currentLanguage === "sv" ? "en" : "sv";
      this.setLanguage(newLocale);
    },
    setLanguage(newLocale: string): void {
      this.$root.$i18n.locale = newLocale;
      document.getElementsByTagName("html")[0].lang = newLocale;
      LocalStorageService.setItem(Constants.Language, newLocale);
      this.currentLanguage = newLocale;
      this.$emit("update:language", newLocale);
    },
    listen(): void {
      // function imported from browsealoud. Why such generic name :(
      // eslint-disable-next-line no-undef
      // toggleBar();
    },
    /**
     * Show the mobile navigation
     */
    showNavMobile(): void {
      this.$refs.navMobile.open();
    },
    /**
     * Toggle search field
     */
    toggleSearch(): void {
      this.searchCollapsed = !this.searchCollapsed;
      if (!this.searchCollapsed) {
        // Wait until rendered to set focus
        this.$nextTick(() => this.$refs.mobileSearchInput.setFocus());
      }
    },
    /**
     * Make a search
     */
    search(): void {
      if (this.searchValue.trim()) {
        this.$emit("search", this.searchValue);
        if (this.emptySearch) {
          this.searchValue = "";
        }
      }
    },
  },
})
export default class LuHeader extends Vue {}
