
import { Options, Vue } from "vue-class-component";
import LuDropdownItem from "@/components/Lu/LuDropdownItem.vue";
import { PropType } from "@vue/runtime-core";
import Badge from "@/components/Badge.vue";
import type { IMenuItem } from "@/interfaces/IMenuItem";

interface Item {
  id: string;
  label?: string;
  text?: string;
  path?: string;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  children: Item[];
}

@Options({
  components: {
    LuDropdownItem,
    Badge,
  },

  data: () => ({
    noteTakerStatusCount: null,

    // Fill with getter from store
    menu: {},
  }),

  props: {
    /**
     * The item to show
     */
    item: { type: Object as PropType<IMenuItem>, required: true },
    /**
     * If this is the last item
     */
    lastItem: Boolean as PropType<boolean>,
  },

  computed: {
    active() {
      /* Check if any child is active */
      let childActive = false;
      if (this.item.children && this.$refs.children) {
        childActive = this.$refs.children
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((child: any) => child.active)
          .includes(true);
      }

      return (
        childActive ||
        this.$route.path === this.item?.path ||
        this.$route.name === this.item?.name
      );
    },
  },
})
export default class LuTopMenuItem extends Vue {}
